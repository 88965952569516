import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from 'components/Button/Button';
import { TextAreaInput } from 'components/Inputs/TextAreaInput';
import React, { useState } from 'react';

interface PageCartoEmbedExportProps {
  pageCartoId: string;
}

const PageCartoEmbedExport: React.FC<PageCartoEmbedExportProps> = ({
  pageCartoId,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const embedCode = `<div id="embed-root"></div>
<script>
    var process = {
        env: {
            REACT_APP_PUBLIC_URL: '',
            REACT_APP_API_URL: window.location.protocol + '//gaiamundi.org/backend/'
        }
    }
</script>
<script src="https://gaiamundi.org/assets/embed.bundle.js"></script>
<script>renderPageCarto('embed-root', { pageCartoId: '${pageCartoId}' });</script>`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(embedCode);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
  };

  return (
    <div className="relative p-4 bg-gray-100 rounded-lg shadow-md">
      <p className="mb-4 text-sm text-gray-600">
        Copiez le code ci-dessous pour intégrer la carte PageCarto sur votre
        site web.
      </p>
      <div className="relative">
        <TextAreaInput readOnly className="h-60 text-xs" value={embedCode} />
        {showTooltip && (
          <div className="absolute top-0 mt-2 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-green-600 rounded shadow-lg">
            <CheckCircleIcon className="w-4 h-4 inline" /> Copié !
          </div>
        )}
        <Button className="mt-4" onClick={handleCopyToClipboard}>
          Copier
        </Button>
      </div>
    </div>
  );
};

export default PageCartoEmbedExport;
